<template>
    <div class="wrapper">
    <!--header-->
    <div class="header">
      <div class="pagesize">
        <div class="headerNav">
          <router-link class="headerlogo" to="./index"><img src="./images/logo.png" alt="">臺北市士林區公所能源管理系統</router-link>
          <div class="state web">
            <div class="circle"></div>通訊正常
          </div>
          <ul class="headerNav_menu web">
            <li><router-link class="on" to="./index">首頁</router-link></li>
            <li><router-link to="./public" >公共用電</router-link></li>
            <li><router-link to="./floor" >樓層用電</router-link></li>
            <li><router-link to="./ice" >冰水主機</router-link></li>
            <li><router-link to="./solar" >光電系統</router-link></li>
            <li><router-link to="./download" >下載專區</router-link></li>
          </ul>
          <div class="menuic mobile btn_menu">
            <img class="btn_pic01 btn_menuck" src="./images/icon_menu.png" alt="">
            <img class="btn_pic02 btn_menuck" src="./images/icon_close.png" alt="">
          </div>
        </div>
      </div>
      <!--小網menu-->
      <div class="open_nav">
        <ul>
          <li class="m_nav"><router-link to="./index">首頁</router-link></li>
          <li class="m_nav"><router-link to="./public">公共用電</router-link></li>
          <li class="m_nav"><router-link to="./floor">樓層用電</router-link></li>
          <li class="m_nav"><router-link to="./ice">冰水主機</router-link></li>
          <li class="m_nav"><router-link to="./solar">光電系統</router-link></li>
          <li class="m_nav"><router-link to="./download">下載專區</router-link></li>
        </ul>
        <div class="state">
          <div class="circle"></div>通訊正常
        </div>
      </div>
    </div>
    <!--內容開始-->
    <div class="contain">
      <div class="pagesize">
        <div class="main">
          <div class="leftfunc">
            <div class="dashboard">
              <div class="date">{{Dates}} 週{{today}}</div>
              <div class="update">更新時間 {{upDateTime}}</div>
              <div class="location">
                <div class="locate">臺北市士林區
                  <div class="temp">{{weather[0]}}°C</div>
                </div>
                <div class="weather">
                  <p>{{weather[2]}}</p>
                  <img :src="weather[1]">
                  <!-- <img src="./images/weather/03.png"> -->
                </div>
              </div>
              <div class="weatherInfo mgr">
                <img src="./images/icon_rain.png">
                <p>降雨機率</p>
                <p>{{pop}}%</p>
              </div>
              <div class="weatherInfo">
                <img src="./images/icon_humidity.png">
                <p>濕度</p>
                <p>{{weather[3]}}%</p>
              </div>
            </div>
            <div style="min-height:50px; min-width:20px;">
              <div class="iceMac" v-for="(i, idx) in iceStastus" :key="idx">
                <p class="iceTit">{{ i.adam }}</p>
                <span v-if="i.adam !== '一號冰機 (未連線)'">
                  <img src="./images/icon_on.png">
                  <p class="iceText" v-if="i.status">降載狀態：{{i.status}}</p>
                  <p class="iceText" v-if="i.offsetStart !== '未設定' && i.offsetEnd !== '未設定'">本日已設定降載: {{secToTime(i.offsetStart)}} ~ {{secToTime(i.offsetEnd)}}</p>
                  <p class="iceText" v-else>未設定</p>
                </span>
                <!-- <p>本日已設定降載</p> -->
                <!-- <p class="iceTime">14:00-16:00</p> -->
              </div>
            </div>
            <div class="graph">
              <p>各樓層本日累積用電量</p>
              <!-- <img src="./images/graph1.png"> -->
              <Highcharts :options="eachFloorEtodayChart" ref="highchart" />
            </div>
            <div class="graph">
              <p>太陽光電本日分時發電量</p>
              <!-- <img src="./images/graph2.png"> -->
              <Highcharts :options="SolarEtodayChart" ref="highchart" />
            </div>
          </div>
          <div class="rightfunc">
            <div class="graph graphM">
              <p>公共用電即時資訊</p>
              <div class="graphInfo">
                <p class="subtit">即時用電</p>
                <div class="flr green">
                  <p class="num">{{publicCurrentKw}}<span>kW</span></p>
                </div>
              </div>
              <div class="graphInfo">
                <p class="subtit">本日累積用電量</p>
                <div class="flr orange">
                  <p class="num">{{publicTotalkwh}}<span>度</span></p>
                </div>
              </div>
              <div class="graphInfo">
                <div class="dialog">
                  <p><span class="green">經常契約容量：</span>376kW<br>
                    <span class="green">本日最高需量占比：</span><br>
                    本日每15分鐘平均需量最高值/376kW x 100%</p>
                </div>
                <p class="subtit">本日最高需量佔比
                  <img src="./images/icon_info.png"></p>
                <div class="flr red">
                  <p class="num">{{this.handleNum(highDemand * 100, 0)}}<span>%</span></p>
                </div>
                <div class="bar">
                  <div class="bar barNum"  :style="highDemandBar"></div>
                </div>
              </div>
              <div class="graphBorder"></div>
              <p>公共用電本日分時用電量</p>
              <!-- <img src="./images/graph3.png"> -->
              <Highcharts :options="PublicEtodayChart" ref="highchart" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--footer-->
    <footer>
      <div class="pagesize">
        <div class="ftLogo"><img src="./images/logo2.png" alt="">
        </div>
        <div class="copyright">
          Copyright © All Rights Reserved.
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped>
    @import './css/global.css';
    .iceText{
      text-align: right;
      font-size:16px
    }
</style>

<script>
import importJQ from './js/common'
import { dbDataHandle } from '../store/function/index'
import { mapState, mapMutations, mapActions } from 'vuex'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import $ from 'jquery'
import { DateTime } from 'luxon'
export default {
  data () {
    return {
      today: '',
      Dates: '',
      hourData: [],
      highDemand: '',
      publicTotalkwh: '',
      publicCurrentKw: '',
      iceStastus: [],
      iceNum: '',
      iceDeloadingStatus: '',
      EachFloorEtoday: {},
      iceAdamStatus: [],
      highDemandBar: {
        width: 0
      },
      eachFloorEtodayChart: {
        chart: {
          type: 'column',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: ['地下室', '樓層七', '樓層八', '樓層九', '樓層十']
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 30
        },
        series: [{
          name: '用電量(度)',
          data: [],
          colorByPoint: true
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
      },
      SolarEtodayChart: {
        chart: {
          type: 'column',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 30
        },
        series: [{
          name: '發電量(度)',
          data: [],
          color: '#f7942f'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
        // , {
        //   // name: 'John',
        //   // data: [5, 7, 3]
        // }]
      },
      PublicEtodayChart: {
        chart: {
          type: 'column',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 30
        },
        series: [{
          name: '用電量(度)',
          data: [],
          color: '#4cc0e2'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'getHourData',
      'getdayData',
      'getLastMerged',
      'getDayInterval'
    ]),
    ...mapMutations([
      'GETUPDATE_TIME'
    ]),
    handleNum (val, num) {
      return dbDataHandle(val, num)
    },
    secToTime (sec) {
      if (sec) {
        const hour = parseInt(sec / 3600)
        const min = (sec % 3600) / 60
        return `${('0' + hour).slice(-2)} : ${('0' + min).slice(-2)}`
      }
    },
    whatDayIsToday () {
      const array = ['日', '一', '二', '三', '四', '五', '六']
      this.Dates = `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`
      this.today = array[new Date().getDay()]
    },
    getPublicTotalkwh () {
      if (this.EdayData.success) {
        const lastOne = this.EdayData.data.length - 1
        this.publicTotalkwh = '-'
        if (this.EdayData.data[lastOne]) {
          this.publicTotalkwh = this.handleNum(this.EdayData.data[lastOne].detail.public.meter)
        }
      }
    },
    async getPublicCurrentKw () {
      await this.getLastMerged()
      if (this.lastCurrentData.success) {
        const publicCurrentKw = this.lastCurrentData.data.detail.METER.find(x => x.deviceKey === 'METER_PUBLIC')
        if (publicCurrentKw) {
          this.publicCurrentKw = this.handleNum(publicCurrentKw.kW)
        } else {
          this.publicCurrentKw = '-'
        }
      }
    },
    async getEachFloorEtoday () {
      const date = {
        beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
        endDate: DateTime.local().toFormat('yyyy-MM-dd')
      }
      await this.getDayInterval(date)
      if (this.InterData.success) {
        this.eachFloorEtodayChart.xAxis.categories = ['地下室', '樓層七', '樓層八', '樓層九', '樓層十']
        const arr = ['METER_B1B2', 'METER_7F', 'METER_8F', 'METER_9F', 'METER_10F']
        for (let i = 0; i < arr.length; i++) {
          const floor = this.InterData.data[0].detail.floor.find(x => x.floor === arr[i])
          if (floor) {
            this.eachFloorEtodayChart.series[0].data.push(this.handleNum(floor.kWh))
          } else {
            this.eachFloorEtodayChart.series[0].data.push(null)
          }
        }
      }
    },
    getSolarEtoday () {
      this.SolarEtodayChart.xAxis.categories = []
      this.SolarEtodayChart.series[0].data = []
      if (this.EdayData.success) {
        for (let i = 0; i < this.EhourData.data.length; i++) {
          if (Number((this.EhourData.data[i].hhmm).slice(0, 2)) >= 6 && Number((this.EhourData.data[i].hhmm).slice(0, 2)) <= 18) {
            this.SolarEtodayChart.xAxis.categories.push((this.EhourData.data[i].hhmm).slice(0, 2))
            this.SolarEtodayChart.series[0].data.push(this.handleNum(this.EhourData.data[i].detail.public.pv))
          }
        }
      }
    },
    getPublicEtoday () {
      this.PublicEtodayChart.xAxis.categories = []
      this.PublicEtodayChart.series[0].data = []
      if (this.EdayData.success) {
        for (let i = 0; i < this.EhourData.data.length; i++) {
          this.PublicEtodayChart.xAxis.categories.push((this.EhourData.data[i].hhmm).slice(0, 2))
          this.PublicEtodayChart.series[0].data.push(this.handleNum(this.EhourData.data[i].detail.public.meter))
        }
      }
    },
    async getHighDemand () {
      const token = VueCookies.get('Authorization')
      const HighDemand = await axios.post('/api/ems/shilin/highDemand',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (HighDemand.data.success) {
        this.highDemand = HighDemand.data.data
        if (this.handleNum(HighDemand.data.data * 100, 0) <= 100) {
          this.highDemandBar.width = `${this.handleNum(HighDemand.data.data * 100, 0)}%`
        } else {
          this.highDemandBar.width = '100%'
        }
      }
    },
    async getlastDocice () {
      const token = VueCookies.get('Authorization')
      const lastDocice = await axios.post('/api/ems/shilin/lastDocice',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (lastDocice.data.success) {
        this.iceStastus = []
        if (!lastDocice.data.data.adam2IsOn || !lastDocice.data.data.adam3IsOn) {
          const obj = {
            adam: '一號冰機 (未連線)',
            status: null
          }
          this.iceStastus.push(obj)
        }
        if (lastDocice.data.data.adam2IsOn) {
          const whichAdam = this.iceAdamStatus.find(x => x.iceNum === 2)
          if (whichAdam) {
            const deloading = whichAdam.deloading ? '開啟' : '關閉'
            const obj = {
              adam: '二號冰機',
              status: deloading,
              offsetStart: whichAdam.offsetStart,
              offsetEnd: whichAdam.offsetEnd
            }
            this.iceStastus.push(obj)
          }
        }
        if (lastDocice.data.data.adam3IsOn) {
          const whichAdam = this.iceAdamStatus.find(x => x.iceNum === 3)
          if (whichAdam) {
            const deloading = whichAdam.deloading ? '開啟' : '關閉'
            const obj = {
              adam: '三號冰機',
              status: deloading,
              offsetStart: whichAdam.offsetStart,
              offsetEnd: whichAdam.offsetEnd
            }
            this.iceStastus.push(obj)
          }
        }
      }
    },
    async getAdamStatus () {
      const token = VueCookies.get('Authorization')
      const adamStatus = await axios.post('/api/ems/shilin/adamsstatus',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (adamStatus.data.success) {
        this.iceAdamStatus = adamStatus.data.data
      }
    }
  },
  async mounted () {
    await importJQ()
    this.whatDayIsToday()
    await this.getHourData()
    await this.getdayData()
    this.getPublicCurrentKw()
    this.getPublicTotalkwh()
    this.getEachFloorEtoday()
    this.getSolarEtoday()
    this.getPublicEtoday()
    this.getHighDemand()
    await this.getAdamStatus()
    this.getlastDocice()
    // this.getAdamStatus()
    this.GETUPDATE_TIME()
    $(function () {
      $(document).ready(function () {
        var $win = $(window)
        $win.bind('resize', function () {
          try {
            $('.contain').css('min-height', $win.height() - $('footer').outerHeight() - $('.contain').offset().top)
          } catch (err) {}
        }).resize()
      })
    })
  },
  computed: {
    ...mapState([
      'weather',
      'pop',
      'upDateTime',
      'EhourData',
      'EdayData',
      'lastCurrentData',
      'InterData'
    ])
  }
}
</script>
